<template>
  <div name="modal" class="Modal"><div >
    <p class="Text Modal-Text">The message is sent</p>
    <button type="button" class="Modal-Close Btn" @click="$emit('close')">OK</button>
  </div></div>
</template>

<script>
export default {
  name: "Modal"
}
</script>

<style lang="scss">

.Modal {
  padding: 40px;
  background-color: var(--color-text-lighter2);
  font-size: 28px;
  text-align: center;

  &-Text {
    margin-bottom: 50px;
  }

  &-Close {
    background-color: var(--color-text-lighter);
    color: var(--color-text-main3);
    width: 100%;
    padding:15px 0;

    &:hover {
      background-color: transparent;
      color: var(--color-text-lighter);
    }
  }
}
</style>