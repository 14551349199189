<template>
  <div class="Partners" :class="paddingPartners">
    <div class="Container">
      <div class="Partners-Logos" :class="logoBottom">
        <img v-for="logo in logos"
             :key="logo.id"
             :src="require(`@/assets/images/${logo}`)"
             :alt="`${logo}`"
             class="Partners-Logo"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partners",
  props: {
    logos: {
      type: Array,
      required: true
    },
    logoBottom: {
      type: String,
      required: false,
      default: ''
    },
    paddingPartners: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.Partners {
  margin-bottom: 78px;
  padding: 42px 0;
  background: var(--color-text-main3);
  box-shadow: var(--color-shadow);

  &-Logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 10px;
    align-items: end;

    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-Logo {
    justify-self: center;

    @media (min-width: $screen-l) {
      filter: grayscale(100%);
      transition: all .3s ease-in-out;
      &:hover {
        filter: none;
      }
    }

  }

  .Partners-Logos--bottom {
    align-items: flex-end;
  }
}
</style>