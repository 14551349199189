<template>
  <div class="Cards">
    <div class="Container">
      <div class="Cards-List">
        <div class="Cards-Item"
             v-for="card in cardInfo"
             :key="card.id"
        >
          <img :src="require(`@/assets/images/${card.icon}`)" alt="icon" class="Cards-ItemIcon">
          <div class="Cards-ItemTitle">
            {{ card.title }}
          </div>
          <div
              class="Cards-ItemSubTitle"
              :class="{'Cards--disable': card.disableSub}"
              v-html="card.subTitle"
          >
          </div>
          <p class="Cards-ItemText">
            {{ card.text }}
          </p>
          <router-link :to="`${card.links}`"
                       class="Btn Cards-ItemLink"
                       :class="{'Cards--disable': card.disableClass}"
          >
            {{ card.linkText }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cards",
  props: {
    cardInfo: {
      type: [Object, Array, String, Boolean],
      required: false,
      default: () => {
      }
    },
  },
}
</script>

<style scoped lang="scss">
.Cards {

  &-List {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: $screen-l) {
      flex-wrap: wrap;
      flex-direction: row;
      align-items: stretch;
    }
  }

  &-Item {
    width: 100%;
    margin: 0 0 25px;
    padding: 43px 18px;
    background-color: var(--color-text-main3);
    text-align: center;
    box-shadow: var(--color-shadow);

    @media (min-width: $screen-l) {
      width: 400px;
      margin: 0 15px 25px;
      position: relative;
    }
  }

  &-ItemIcon {
    margin-bottom: 24px;
    width: 132px;
    @media (min-width: $screen-l) {
      margin-bottom: 34px;
    }
  }

  &-ItemTitle {
    margin-bottom: 29px;
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.69em;
    text-transform: uppercase;
    color: var(--color-text-main2);

    @media (min-width: $screen-l) {
      margin-bottom: 39px;
      font-size: 30px;
    }
  }

  &-ItemSubTitle {
    margin-bottom: 18px;
    padding: 0 16px;
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.69em;
    color: var(--color-text-lighter);

    @media (min-width: $screen-l) {
      margin-bottom: 22px;
      font-size: 25px;
      padding: 0 20px;
    }
  }

  &-ItemText {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.69em;
    color: var(--color-text-main);
  }

  &-ItemLink {
    padding: 20px 99px;
    color: var(--color-text-lighter);

    @media (min-width: $screen-xl) {
      position: absolute;
      right: 67px;
      left: 67px;
      bottom: 33px;
    }

    &:hover {
      color: var(--color-text-main3);
      background-color: var(--color-text-lighter);
    }
  }
}

.Cards--disable {
  display: none;
}
</style>