<template>
  <section class="FormBox">
    <div class="Container">
      <h2 class="Title FormBox-Title">Questions?</h2>
      <div class="FormBox-Content">
        <div class="FormBox-Form">
          <h3 class="FormBox-ContentTitle">WRITE US</h3>
          <form class="Form" @submit.prevent="onSubmit()" novalidate>
            <div class="Form-Top">
              <div class="Form-Field">
                <input type="text"
                       class="Form-TopInput Input"
                       name="name"
                       placeholder="Name"
                       v-model.trim="form.name"
                       :class="$v.form.name.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.name.$dirty && !$v.form.name.required"
                      class="invalid-feedback">Required field!</span>

              </div>
              <div class="Form-Field">
                <input type="text"
                       class="Form-TopInput Input"
                       name="phone"
                       placeholder="Phone"
                       v-model.trim="form.phone"
                       :class="$v.form.phone.$error ? 'is-invalid' : ''"
                >
                <span v-if="$v.form.phone.$dirty && !$v.form.phone.required"
                      class="invalid-feedback">Required field!</span>
              </div>
            </div>
            <div class="Form-Field">
              <input type="email"
                     class="Form-Input Input"
                     name="email"
                     placeholder="Email"
                     v-model.trim="form.email"
                     :class="$v.form.email.$error ? 'is-invalid' : ''"
              >
              <span v-if="$v.form.email.$dirty && !$v.form.email.required"
                    class="invalid-feedback">Required field!</span>
              <span v-if="$v.form.email.$dirty && !$v.form.email.email"
                    class="invalid-feedback">Incorrect email</span>
            </div>
            <div class="Form-Field">
              <textarea name="message"
                        class="Form-Textarea Input"
                        placeholder="Text"
                        v-model.trim="form.message"
                        :class="$v.form.message.$error ? 'is-invalid' : ''"
              ></textarea>

              <span v-if="$v.form.message.$dirty && !$v.form.message.required"
                    class="invalid-feedback">Required field!</span>

            </div>
            <button type="submit" class="Btn Form-Btn">send</button>
          </form>
        </div>
        <div class="FormBox-Contacts">
          <h3 class="FormBox-ContentTitle">CONTACT US</h3>
          <a href="mailto:office@scartesu.com" class="FormBox-Contact">office@scartesu.com</a>
          <div class="FormBox-Contact">
            A.G. Leventi 5, The Leventis Gallery Tower, 13th Floor, Flat|Office 1301, 1097, Nicosia, Cyprus
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {email, required} from "vuelidate/lib/validators";
import axios from 'axios';
import Modal from "@/components/Modal";

export default {
  mixins: [validationMixin],
  name: "Form",
  data() {
    return {
      form: {
        name: '',
        phone: '',
        email: '',
        message: '',
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email
      },
      message: {
        required,
      },
    }
  },
  methods: {
    showAuthDialog() {
      this.$modal.show(
          Modal,
          {},
          {
            width: 428,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            style: "{ backgroundColor: 'rgba(1,7,11,.7)'}",
            clickToClose: false,
          },
          {},
      );
    },
    onSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        const params = new URLSearchParams();
        params.append('name', this.form.name);
        params.append('phone', this.form.phone);
        params.append('email', this.form.email);
        params.append('message', this.form.message);

        axios.post(
            "/mail.php",
            params,
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              }
            }
        ).then(() => {

        })
        this.showAuthDialog()
        this.form.name = '',
            this.form.phone = '',
            this.form.email = '',
            this.form.message = ''
        this.$nextTick(() => {
          this.$v.$reset()
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.FormBox {
  padding: 46px 0;

  @media (min-width: $screen-l) {
    padding: 146px 0;
  }

  &-Title {
    margin-bottom: 75px;

    @media (min-width: $screen-l) {
      margin-bottom: 115px;
      &:before {
        left: -890px;
      }
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    box-shadow: var(--color-shadow);

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-ContentTitle {
    margin-bottom: 41px;
    font-family: 'Lexend', sans-serif;
    font-weight: 800;
    font-size: 35px;
    text-transform: uppercase;
  }

  &-Form {
    width: 100%;
    padding: 42px 15px;
    background-color: var(--color-text-main3);

    @media (min-width: $screen-l) {
      max-width: 665px;
      padding: 42px 55px 41px 58px;
    }
  }

  &-Contacts {
    width: 100%;
    padding: 42px 55px 41px;
    background-color: var(--color-text-light);

    @media (min-width: $screen-l) {
      max-width: 640px;
    }
  }

  &-Contact {
    font-size: 22px;
    line-height: 1.69;
    margin-bottom: 20px;

    span {
      text-transform: uppercase;
    }
  }
}

.Form {
  display: flex;
  flex-direction: column;

  &-Top {
    width: 100%;

    @media (min-width: $screen-l) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .Form-Field {
      margin-right: 15px;
    }
  }

  &-Field {
    width: 100%;
    max-width: 100%;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: $screen-l) {
      margin-right: 0;
      width: auto;
      max-width: 100%;
    }
  }

  &-Input {
    max-width: 100%;
    width: 100%;
  }

  &-Textarea {
    width: 100%;
    height: 185px;
    margin-bottom: 40px;
  }

  .Input {
    max-width: 100%;
    width: 100%;
    padding: 25px 18px 24px;
    border: 2px solid var(--color-text-lighter2);
    background-color: var(--color-text-lighter2);
    outline: none;
    resize: none;
    font-family: 'Questrial', sans-serif;
    font-size: 22px;
    color: var(--color-text-main2);

    &::placeholder {
      color: var(--color-text-placeholder);
    }

    &:focus {
      border: 2px solid var(--color-text-lighter);
    }
  }

  &-Btn {
    padding: 23px;
    background-color: var(--color-text-lighter);
    color: var(--color-text-main3);
    transition: .3s;

    &:hover {
      background-color: var(--color-text-main3);
      color: var(--color-text-lighter);
    }
  }

  .invalid-feedback {
    position: absolute;
    top: -15px;
    right: 0;
    font-size: 12px;
    color: var(--color-text-error);
  }
}

</style>
