<template>
  <div class="TitleBox">
    <div class="Container">
      <h2
          class="Title TitleBox-TitleRight"
          :class="titleDouble"
          v-html="title"
      ></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleRight",
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    titleDouble: {
      type: String,
      required: false,
      default: "",
    }
  }
}
</script>

<style lang="scss">
.TitleBox {
  margin: 50px 0 20px;
  text-align: right;
  overflow: hidden;

  span {
    display: inline;
  }

  @media (min-width: $screen-l) {
    margin: 100px 0 70px;
    span {
      display: block;
    }
    &-Title-Right {
      &:before {
        right: -305px;
      }
    }
  }
}
</style>